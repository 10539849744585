import React from "react";
import "./WinModal.scss";

const WinModal = props => {
  return (
    <div className="win-modal">
      <div
        className="win-modal-inside animate__animated animate__bounceIn"
        onClick={props.onClose}
      >
        <div style={{textTransform: "uppercase"}}>{props.itemCfg.winMsg}</div>
        <img src={props.itemCfg.winImage} />
      </div>
    </div>
  );
};

export default WinModal;
