import React, {useRef, useState} from "react";
import "./App.css";
import SketchComponent from "./SketchComponent";
import WinModal from "./WinModal";
import getConfig from "./getConfig";

const getWinningIndex = (items) => {
  const blockedIndexesJSON = localStorage.getItem("blocked-indexes");
  const blockedIndexes = blockedIndexesJSON ? JSON.parse(blockedIndexesJSON) : [];
  const tickets = [];
  items.forEach((item, i)=>{
    if(!blockedIndexes.includes(i)) {
      for(let j = 0; j < item.chance; j++) {
        tickets.push(i);
      }
    }
  });
  if(tickets.length === 0) {
    throw new Error("All items have 0 chance to drop or are blocked.");
  }
  const winningIndex = tickets[Math.floor(Math.random() * tickets.length)];
  blockedIndexes.push(winningIndex);
  localStorage.setItem("blocked-indexes", JSON.stringify(blockedIndexes));
  return winningIndex;
};

function App() {
  const [showWinModal, setShowWinModal] = useState(false);
  const [winIndex, setWinIndex] = useState(null);
  const controlRef = useRef(null);

  let config = getConfig();
  if(config.items.length < 10) return "At least 10 item needs to be defined.";
  if (config.items.length % 2 != 0)
    return "Even number of items needs to be defined.";

  const _onClick = () => {
    if(showWinModal) return;
    const winningIndex = getWinningIndex(config.items);
    setWinIndex(winningIndex);
    controlRef.current.startRotationToItem(winningIndex);
  };

  const _onFinish = () => {
    setShowWinModal(true);
  };


  return (
    <div onClick={_onClick} style={{height: "100vh", width: "100%"}}>
      {showWinModal && <WinModal itemCfg={config.items[winIndex]} onClose={()=>setShowWinModal(false)} />}
      <SketchComponent onFinish={_onFinish} controlsCallback={(cb)=>controlRef.current = cb} config={config} />
    </div>
  );
}

export default App;
