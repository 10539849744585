import React, { useState, useRef } from "react";
import "./Admin.scss";
import getConfig from "./getConfig";

const Admin = () => {
  const formRef = useRef(null);
  const textAreaRef = useRef(null);
  const storageConfig = getConfig();
  const [localConfig, setLocalConfig] = useState(
    storageConfig
  );
  const [showCopypaste, setShowCopypaste] = useState(false);

  const _save = (e) => {
    e.preventDefault();
    window.localStorage.setItem("kolo_config", JSON.stringify(localConfig));
    setShowCopypaste(false);
    alert("saved");
  };

  const _saveFromTextarea = () => {
    const textareaValue = textAreaRef.current.value;
    window.localStorage.setItem("kolo_config", textareaValue);
    setLocalConfig(getConfig());
    alert("saved");
  };

  const _addItem = (e) => {
    e.preventDefault();
    const newItems = [...localConfig.items];
    newItems.push({});
    setLocalConfig({...localConfig, items: newItems});
  };

  const _deleteItem = (e,index) => {
    e.preventDefault();
    const newItems = [...localConfig.items];
    newItems.splice(index, 1);
    setLocalConfig({...localConfig, items: newItems});
  };

  const _onChange = (e) => {
    const [key, indexStr] = e.target.id.split("-");
    const index = parseInt(indexStr);
    const newItems = localConfig.items.map(item=>({...item}));
    newItems[index][key] = e.target.value;
    setLocalConfig({...localConfig, items: [...newItems]});
  };

  const _getItemConfig = () => {
    return localConfig.items.map((item, i)=>
      <div key={i}>
        <div style={{display: "inline-block", minWidth: 30}}>{i+1}{". "}</div>
        <label htmlFor={"image-"+i}>Image (80x200)</label>
        <input onChange={_onChange} id={"image-"+i} value={item.image} />
        <label htmlFor={"winImage-"+i}>Win image (480x320)</label>
        <input onChange={_onChange} id={"winImage-"+i} value={item.winImage} />
        <label htmlFor={"winMsg-"+i}>Win message</label>
        <input onChange={_onChange} id={"winMsg-"+i} value={item.winMsg} />
        <label htmlFor={"chance-"+i}>Chance (whole number, 0 - ∞)</label>
        <input onChange={_onChange} id={"chance-"+i} value={item.chance} />
        <button onClick={(e)=>_deleteItem(e,i)}>Delete</button>
      </div>
    );
  };

  const blockedIndexesJSON = localStorage.getItem("blocked-indexes");
  const blockedIndexes = blockedIndexesJSON ? JSON.parse(blockedIndexesJSON) : [];

  return (
    <div className="admin">
      <div style={{marginBottom: 24}}>Blocked items: {blockedIndexes.length ? blockedIndexes.map(i=>i+1).join(",") : "none"}<button style={{marginLeft: 8}} onClick={()=>{
        localStorage.setItem("blocked-indexes", JSON.stringify([]));
        setLocalConfig({...localConfig});
      }}>Clear</button></div>
      <form onSubmit={_save} ref={r=>formRef.current = r}>
      {_getItemConfig()}
        <button onClick={_addItem}>Add item</button><button type="submit">Save</button>
      </form>
      <button style={{marginTop: 30}} onClick={()=>setShowCopypaste(!showCopypaste)}>Import / Export</button>
      {showCopypaste && <textarea ref={r=>textAreaRef.current = r} >{JSON.stringify(storageConfig)}</textarea>}
      {showCopypaste && <button onClick={_saveFromTextarea}>Save from textarea</button>}
    </div>
  );
};

export default Admin;
