import React, { Component } from "react";
import Sketch from "react-p5";

const colors = ["#5AA098", "#6BB2AA"];

let targetRotation = 0; // 0
let startingRotation = 0; // 0

const startingVelocity = 0.04; // 0.04
const endingVelocity = 0.00005; // 0.00005
const spinCount = 4; // 4

let currentRotation = startingRotation;
let currentVelocity = startingVelocity;

let wasFinishedBefore = true;
function randomInteger(min, max) {
  const edgeCut = (max - min)*0.01;
  min = Math.ceil((min+edgeCut)*10000000);
  max = Math.floor((max-edgeCut)*10000000);
  const x = Math.floor(Math.random() * (max - min + 1)) + min;
  return x/10000000;
}

const isFinished = () => {
  return currentRotation === targetRotation;
};

const getTargetRotation = (itemCount, targetIndex, currentRotation) => {
  const rotationStep = 2 / itemCount;
  const remainingRotation = currentRotation % 2;

  const newRotation =
    currentRotation -
    remainingRotation -
    randomInteger(rotationStep * targetIndex, rotationStep * (targetIndex+1)) +
    spinCount * 2;

  return newRotation;
};

const width = window.innerWidth;
const height = window.innerHeight;
const middleX = 0;
const middleY = 0;

const r = height < width ? height * 0.45 : width * 0.45;
const imgSize = [r*0.45*(80/200), r*0.45];
const imgR = r - (imgSize[1]/ 2) - 8;

let logoImg;
export default class App extends Component {
  images = [];

  setup = (p5, canvasParentRef) => {
    this.props.config.items.forEach(item => {
      this.images.push(p5.loadImage(item.image));
    });

    logoImg = p5.loadImage("https://i.imgur.com/3gdavuH.png");
    p5.createCanvas(width, height).parent(canvasParentRef);
    p5.frameRate(60);
    p5.imageMode(p5.CENTER);
    p5.smooth();
  };

  startRotationToItem = index => {
    if (!isFinished()) return;
    targetRotation = getTargetRotation(
      this.props.config.items.length,
      index,
      currentRotation
    ); // ~5

    startingRotation = currentRotation;
    currentVelocity = startingVelocity;
  };

  drawTriangles = (p5, count) => {
    let middleAngles = [];
    let angle = -0.5 * Math.PI;
    for (let i = 0; i < count; i++) {
      p5.push();
      p5.noStroke();
      p5.fill(colors[i % colors.length]);
      const x1 = middleX;
      const y1 = middleY;
      const x2 = middleX + r * Math.cos(angle);
      const y2 = middleY + r * Math.sin(angle);
      angle += (2 * Math.PI) / (count * 2);
      middleAngles.push(angle + currentRotation * Math.PI);
      angle += (2 * Math.PI) / (count * 2);
      const x3 = middleX + r * Math.cos(angle);
      const y3 = middleY + r * Math.sin(angle);
      p5.triangle(x1, y1, x2, y2, x3, y3);
      p5.pop();
    }

    return middleAngles;
  };

  processRotation = p5 => {
    if (!isFinished()) {
      currentVelocity = p5.map(
        currentRotation,
        startingRotation,
        targetRotation,
        startingVelocity,
        endingVelocity,
        true
      );
      currentRotation += currentVelocity;
      if (currentRotation > targetRotation) currentRotation = targetRotation;
    }
  };

  drawImages = (p5, middleAngles) => {
    middleAngles.forEach((middleAngle, i) => {
      const x = middleX + imgR * Math.cos(middleAngle);
      const y = middleY + imgR * Math.sin(middleAngle);
      p5.push();
      p5.translate(x, y);
      p5.rotate(middleAngle + 0.5 * Math.PI);
      p5.translate(0, 0);
      p5.image(this.images[i], 0, 0, imgSize[0], imgSize[1]);
      p5.pop();
    });
  };

  draw = p5 => {
    const isFinishedResult = isFinished();
    if(!wasFinishedBefore && isFinishedResult) this.props.onFinish();

    this.props.controlsCallback({
      startRotationToItem: this.startRotationToItem
    });

    p5.translate(width / 2, height / 2);
    this.processRotation(p5);
    p5.background("#fafafa");
    p5.push();
    p5.rotate(currentRotation * Math.PI);
    const middleAngles = this.drawTriangles(p5, this.props.config.items.length);
    p5.pop();
    this.drawImages(p5, middleAngles);
    p5.image(logoImg, 0, 0, r*0.88, r*0.88*(110/290));
    p5.push();
    p5.fill("red");
    p5.triangle(0, -r + 10, -20, -r - 20, 20, -r - 20);
    p5.pop();

    wasFinishedBefore = isFinishedResult;
  };

  render() {
    return (
      <Sketch
        setup={this.setup}
        draw={this.draw}
        mouseClicked={this.mouseClicked}
      />
    );
  }
}
